html {
  scrollbar-width:none;
}

body::-webkit-scrollbar {
  display: none;
}

body {
  height: 100%;
  position: relative;
  margin: 0;
  padding: 0;
  font-family: "Noto Sans TC", "Roboto", "Segoe UI", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
  margin: 0;
}